import {
    debounce,
    monitorForChanges,
} from "../components/helpers/_observer-handler";
import handleMediaQueryChange from "../components/helpers/_media_query";
import { isIosDevices, isSafari } from "../common/_constants";

// Mobile Sidebar Menu
const toggleSidebarHiddenVisible = (button, sidebar) => {
    const buttonSelector = document.querySelector(button);
    const sidebarSelector = document.querySelector(sidebar);

    if (!buttonSelector || !sidebarSelector) {
        return;
    }

    if (sidebarSelector.classList.contains("hidden")) {
        sidebarSelector.classList.remove("hidden");
    }

    const toggleSidebarClickHandler = () => {
        sidebarSelector.classList.toggle("translate-x-full");
        sidebarSelector.classList.toggle("translate-x-0");
        document.body.classList.toggle("sidebar-expanded");

        if (isIosDevices()) {
            document.body.classList.toggle("fixed");
        }
    };

    if (!buttonSelector._clickHandlerAdded) {
        buttonSelector.addEventListener("click", toggleSidebarClickHandler);
        buttonSelector._clickHandlerAdded = true;
    }

    adjustTextScaleForFeaturedSports();
};

const toggleSidebarOnDesktop = (sidebar) => {
    const sidebarSelector = document.querySelector(sidebar);

    if (sidebarSelector.length === 0) {
        return;
    }

    if (sidebarSelector.classList.contains("translate-x-0")) {
        sidebarSelector.classList.toggle("translate-x-full");
        sidebarSelector.classList.toggle("translate-x-0");
        sidebarSelector.classList.add("hidden");
        document.body.classList.toggle("sidebar-expanded");

        if (isIosDevices()) {
            document.body.classList.toggle("fixed");
        }
    }
};

const adjustTextScaleForFeaturedSports = () => {
    const featuredSport = document.querySelector("#featured-sports");

    if (featuredSport) {
        const containers = featuredSport.querySelectorAll("a");

        containers.forEach((container) => {
            const text = container.querySelector("p");
            if (text) {
                text.classList.remove("smaller-size", "one-word");
            }
        });

        containers.forEach((container) => {
            const text = container.querySelector("p");
            if (!text) return;

            const trimmedText = text.textContent.trim();
            const words = trimmedText.split(/\s+/).filter((word) => word.length > 0);

            if (words.length === 1) {
                text.classList.add("one-word");
            }

            if (text.clientWidth > container.clientWidth) {
                text.classList.add("smaller-size")
            }
        });
    }
};

const adjustGridRowForMobileMenus = (menuId) => {
    const parentMenuElement = document.querySelector(menuId);

    if (!parentMenuElement) return;

    const numberOfMenuItems = parentMenuElement?.children.length;
    const gridRowsNumber = Math.ceil(numberOfMenuItems / 2);
    parentMenuElement.style.gridTemplateRows = `repeat(${gridRowsNumber}, 1fr)`;
};

const toggleMobileSidebarMenu = () => {
    const burgerMenuLocator = "#burgerMenu";
    const sidebarMenuLocator = "#sidebarMenu";
    const sidebarCloseButtonLocator = "#sidebarCloseButton";
    const primaryMenuLocator = "#sidebarPrimaryMenu";
    const additionalMenuLocator = "#sidebarAdditionalMenu";

    handleMediaQueryChange(
        window.addEventListener(
            "resize",
            debounce(adjustTextScaleForFeaturedSports),
        ),
        null,
    );
    adjustGridRowForMobileMenus(primaryMenuLocator);
    adjustGridRowForMobileMenus(additionalMenuLocator);
    toggleSidebarHiddenVisible(burgerMenuLocator, sidebarMenuLocator);
    toggleSidebarHiddenVisible(sidebarCloseButtonLocator, sidebarMenuLocator);
};

export const expandMobileSidebarMenu = () => {
    const sidebarMenuLocator = "#sidebarMenu";

    const mobileSidebarHandler = () => toggleMobileSidebarMenu();

    const desktopSidebarHandler = () => {
        toggleSidebarOnDesktop(sidebarMenuLocator);
    };

    handleMediaQueryChange(mobileSidebarHandler, desktopSidebarHandler);
};

const resetMobileNavScrollBehavour = () => {
    const mainNavigation = document.querySelector("#mainNavigation");
    const secondaryMenu = document.querySelector("#secondaryMenu");

    if (mainNavigation.classList.contains("condensed-state"))
        mainNavigation.classList.remove("condensed-state");
    if (mainNavigation.classList.contains("expanded-state"))
        mainNavigation.classList.remove("expanded-state");
    if (secondaryMenu?.classList.contains("condensed-state"))
        secondaryMenu.classList.remove("condensed-state");
    if (secondaryMenu?.classList.contains("expanded-state"))
        secondaryMenu.classList.remove("expanded-state");

    window.removeEventListener("scroll", setMobileNavStates);
};

let mobileLastScrollTop = 0;
const setMobileNavStates = () => {
    const mainNavigation = document.querySelector("#mainNavigation");
    const secondaryMenu = document.querySelector("#secondaryMenu");

    if (!mainNavigation) return;

    let scrollTop = window.scrollY || document.documentElement.scrollTop;

    if (scrollTop > mobileLastScrollTop) {
        mainNavigation.classList.add("condensed-state");
        mainNavigation.classList.remove("expanded-state");
        secondaryMenu?.classList.add("condensed-state");
        secondaryMenu?.classList.remove("expanded-state");
    } else {
        mainNavigation.classList.remove("condensed-state");
        mainNavigation.classList.add("expanded-state");
        secondaryMenu?.classList.remove("condensed-state");
        secondaryMenu?.classList.add("expanded-state");
    }

    mobileLastScrollTop = scrollTop;
};

const mobileNavScrollBehavour = () => {
    window.addEventListener("scroll", setMobileNavStates);
};

export const condensedMobileNavigationBar = () => {
    handleMediaQueryChange(
        mobileNavScrollBehavour,
        resetMobileNavScrollBehavour,
    );
};

// Mobile Edition Menu
const toggleEditionMenu = (button, buttonArrow, editions) => {
    const buttonSelector = document.querySelector(button);
    const buttonArrowSelector = document.querySelector(buttonArrow);
    const editionsSelector = document.querySelector(editions);

    if (!buttonSelector || !editionsSelector || !buttonArrowSelector) {
        return;
    }

    const editionsContainer = editionsSelector?.firstElementChild;
    const expandedHeight = `${editionsContainer.offsetHeight}px`;
    const collapsedHeight = "0px";
    editionsSelector.style.height = collapsedHeight;

    const toggleeditionClickHandler = () => {
        const currentHeight = editionsSelector.style.height;

        editionsSelector.style.height =
            currentHeight === collapsedHeight
                ? expandedHeight
                : collapsedHeight;

        // Toggle the class for the button
        buttonArrowSelector.classList.toggle("open");
    };

    if (!buttonSelector._clickHandlerAdded) {
        buttonSelector.addEventListener("click", toggleeditionClickHandler);
        buttonSelector._clickHandlerAdded = true;
    }
};

export const expandMobileEditionMenu = () => {
    const editionButtonLocator = "#mobileEditionButton";
    const editionsMenuLocator = "#mobileEditionsMenu";
    const editionButtonArrowLocator = "#mobileEditionButtonArrow";

    const mobileEditionsHandler = () => {
        toggleEditionMenu(
            editionButtonLocator,
            editionButtonArrowLocator,
            editionsMenuLocator,
        );
    };

    handleMediaQueryChange(mobileEditionsHandler, null);
};

// Mobile Sub Menu Condensed/Expanded State
const touchEventHandler = (secondaryMenu, thirdLevel) => {
    let startX = 0;
    let isSwiping = false;

    const secondaryMenuContainer = document.querySelector(secondaryMenu);
    const thirdLevelContainer = document.querySelector(thirdLevel);
    const thirdLevelTitle = thirdLevelContainer.querySelector("div");

    thirdLevelTitle.style.width = `${thirdLevelTitle.offsetWidth}px`;

    secondaryMenuContainer.addEventListener("touchstart", (event) => {
        startX = event.touches[0].clientX;
        isSwiping = true;
    });

    secondaryMenuContainer.addEventListener("touchmove", (event) => {
        if (!isSwiping) return;

        const currentX = event.touches[0].clientX;
        const deltaX = currentX - startX;

        if (deltaX < 0) {
            thirdLevelTitle.style.width = 0;
        }
    });

    secondaryMenuContainer.addEventListener("touchend", () => {
        isSwiping = false;
    });
};

const swipeGestureHandler = () => {
    const subMenuLocator = "#secondaryMenu";
    const subMenuItemsLocator = "#mobileSecondaryNavigation";
    const thirdLevelLocator = "#secondaryMenu nav #brandLogoAndName";
    const activeSportLogoLocator =
        "#secondaryMenu nav #brandLogoAndName #activeSportLogo";

    const mobileSecondaryNavigation =
        document.querySelector(subMenuItemsLocator);
    const activeSportLogo = document.querySelector(activeSportLogoLocator);

    if (!activeSportLogo) return;

    if (mobileSecondaryNavigation) {
        const isHorizontallyScrollable =
            mobileSecondaryNavigation.scrollWidth >
            mobileSecondaryNavigation.clientWidth;

        if (isHorizontallyScrollable) {
            touchEventHandler(subMenuLocator, thirdLevelLocator);
        }
    }
};

export const toggleMobileSubMenuLogo = () => {
    handleMediaQueryChange(swipeGestureHandler, null);
    handleMediaQueryChange(
        window.addEventListener("resize", debounce(swipeGestureHandler)),
        null,
    );
};

// Desktop Hover state of sports menu upon hovering more menu
const sportMenusHoverHandler = () => {
    const moreMenu = document.querySelector("#desktopAdditionalMenu");

    if (!moreMenu) {
        return;
    }

    moreMenu.addEventListener("mouseover", () =>
        toggleSportMenusTextColor(true),
    );
    moreMenu.addEventListener("mouseout", () =>
        toggleSportMenusTextColor(false),
    );

    function toggleSportMenusTextColor(isHovered) {
        const sportsMenu = moreMenu.previousElementSibling;

        if (!sportsMenu) {
            return;
        }

        if (isHovered) {
            sportsMenu.classList.add("text-primary-grey");
        } else {
            sportsMenu.classList.remove("text-primary-grey");
        }
    }

    const additinalMenuColumn = document.querySelectorAll(
        "[data-additional-sub-menu-columns]",
    );
    preSetHoverBoldText(additinalMenuColumn, "column");
};

export const hoverStateDesktopSportMenu = () => {
    handleMediaQueryChange(null, sportMenusHoverHandler);
};

// Desktop navigation logo condensed state
let desktopLastScrollTop = 0;
const updateDesktopLogo = () => {
    let scrollTop = window.scrollY || document.documentElement.scrollTop;
    const tsnDesktopLogo = document.querySelector("#tsnLogo");

    if (!tsnDesktopLogo) {
        return;
    }

    if (scrollTop > desktopLastScrollTop) {
        tsnDesktopLogo.classList.remove(
            "bg-expanded-tsn-logo",
            "md:w-200px",
            "md:h-5",
        );
        tsnDesktopLogo.classList.add(
            "bg-condensed-tsn-logo",
            "md:h-8",
            "md:w-8",
        );
    } else {
        tsnDesktopLogo.classList.add(
            "bg-expanded-tsn-logo",
            "md:w-200px",
            "md:h-5",
        );
        tsnDesktopLogo.classList.remove(
            "bg-condensed-tsn-logo",
            "md:h-8",
            "md:w-8",
        );
    }
    desktopLastScrollTop = scrollTop;
};

const toggleTSNLogo = () => {
    let ticking = false;

    const scrollHandler = () => {
        if (!ticking) {
            window.requestAnimationFrame(() => {
                updateDesktopLogo();
                ticking = false;
            });

            ticking = true;
        }
    };

    updateDesktopLogo();

    return scrollHandler;
};

const enableToggleTSNLogo = () => {
    window.addEventListener("scroll", toggleTSNLogo);
};

const disableToggleTSNLogo = () => {
    const tsnDesktopLogo = document.querySelector("#tsnLogo");

    if (
        tsnDesktopLogo &&
        tsnDesktopLogo.classList.contains(
            "bg-condensed-tsn-logo",
            "md:h-8",
            "md:w-8",
        )
    ) {
        tsnDesktopLogo.classList.add(
            "bg-expanded-tsn-logo",
            "md:w-200px",
            "md:h-5",
        );
        tsnDesktopLogo.classList.remove(
            "bg-condensed-tsn-logo",
            "md:h-8",
            "md:w-8",
        );
    }
    window.removeEventListener("scroll", toggleTSNLogo);
};

export const condensedStateDesktopLogo = () => {
    handleMediaQueryChange(disableToggleTSNLogo, enableToggleTSNLogo);
};

// Desktop sub navigation height with teams handlers
const setSubNavItemsHeight = () => {
    const subNavContainers = document.querySelectorAll(
        "#desktopNavigation [data-has-dropdown]",
    );

    subNavContainers.forEach((subNavContainer) => {
        const menu = subNavContainer.querySelector("[data-sub-menu]");
        const teams = subNavContainer.querySelector("[data-sub-menu-team]");

        if (teams?.classList.contains("md:hidden")) return;

        if (!menu || menu.classList.contains("md:hidden")) return;

        const TEAM_NAME_MAX_WIDTH = 148;
        const items = menu.querySelector("ul");
        const titles = items.querySelectorAll("a");
        let longestTitleWidth = 0;

        titles.forEach((title) => {
            if (
                title.clientWidth > TEAM_NAME_MAX_WIDTH &&
                title.clientWidth > longestTitleWidth
            ) {
                longestTitleWidth = title.clientWidth;
            }
        });

        if (longestTitleWidth > TEAM_NAME_MAX_WIDTH) {
            titles.forEach((title) => {
                title.style.width = `${longestTitleWidth}px`;
            });
        }

        if (!teams || !isSafari()) return;

        if (menu.scrollHeight - 1 > menu.clientHeight) {
            const contentHeight = menu.scrollHeight;

            menu.style.height = `${contentHeight}px`;
        }
    });
};

const observeTeamsContainerHeight = () => {
    let prevHeight = null;
    const teamsContainers = document.querySelector(
        "#desktopNavigation [data-sub-menu-team]",
    );

    if (!teamsContainers) return;

    const teamsContainerResize = new ResizeObserver((entries) => {
        let rect = entries[0].contentRect;
        let height = rect.height;

        if (prevHeight !== null && height !== prevHeight) {
            setSubNavItemsHeight();
        }

        prevHeight = height;
    });

    if (teamsContainers) {
        teamsContainerResize.observe(teamsContainers);
    }

    setSubNavItemsHeight();
};

const sportDropdownWidthSafari = () => {
    const subNavContainers = document.querySelectorAll(
        "#desktopNavigation [data-has-dropdown]",
    );

    if (!isSafari() || subNavContainers.length === 0) return;

    subNavContainers.forEach((subNavContainer) => {
        const menu = subNavContainer.querySelector("[data-sub-menu]");

        if (!menu) return;

        const container = menu.querySelector("ul");
        const items = container.querySelectorAll("li");
        const twoColumnsSubMenuDropdown = items.length > 10;

        if (twoColumnsSubMenuDropdown) {
            container.style.width = `${container.offsetWidth * 2}px`;
        }
    });
};

const sportSubMenuHoverBold = () => {
    const activeSubMenuItems = document.querySelectorAll(
        "#desktopSecondaryNavigation > a",
    );
    preSetHoverBoldText(activeSubMenuItems, "row");
};

export const updateSubNavItemStyles = () => {
    handleMediaQueryChange(null, sportSubMenuHoverBold);
    handleMediaQueryChange(null, observeTeamsContainerHeight);
    handleMediaQueryChange(null, sportDropdownWidthSafari);
};

// Desktop ticker navigation dropdown offset position & hover state
const toggleDropdown = (ticker, tickerNavigation, dropdown, isHovered) => {
    if (isHovered) {
        dropdown.classList.add("visible", "opacity-100");
        dropdown.classList.remove("invisible", "opacity-0");
        ticker.classList.add("text-white-js");
        tickerNavigation.classList.add("grey-text-js");
    } else {
        dropdown.classList.add("invisible", "opacity-0");
        dropdown.classList.remove("visible", "opacity-100");
        ticker.classList.remove("text-white-js");
        tickerNavigation.classList.remove("grey-text-js");
    }
};

const setupTickerHover = (ticker, tickerNavigation, dropdown) => {
    let isTickerHovered = false;

    const handleTickerMouseOver = () => {
        isTickerHovered = true;
        toggleDropdown(ticker, tickerNavigation, dropdown, true);
    };

    const handleTickerMouseOut = (event) => {
        if (
            !ticker.contains(event.relatedTarget) &&
            !dropdown.contains(event.relatedTarget)
        ) {
            isTickerHovered = false;
            toggleDropdown(ticker, tickerNavigation, dropdown, false);
        }
    };

    const handleDropdownMouseOver = () => {
        if (isTickerHovered) {
            toggleDropdown(ticker, tickerNavigation, dropdown, true);
        }
    };

    const handleDropdownMouseOut = (event) => {
        if (isTickerHovered && !ticker.contains(event.relatedTarget)) {
            toggleDropdown(ticker, tickerNavigation, dropdown, false);
        }
    };

    ticker.addEventListener("mouseover", handleTickerMouseOver);
    ticker.addEventListener("mouseout", handleTickerMouseOut);
    dropdown.addEventListener("mouseover", handleDropdownMouseOver);
    dropdown.addEventListener("mouseout", handleDropdownMouseOut);
};

export const nbaTickerHoverHandler = () => {
    const nbaTeamsTicker = document.querySelector("#nbaTeamsTicker");
    const nbaGlobalTicker = document.querySelector("#nbaGlobalTicker");
    const nbaTeamsDropdown = document.querySelector("#nbaTeamsDropdown");
    const nbaGlobalDropdown = document.querySelector("#nbaGlobalDropdown");
    const tickerNavigation = document.querySelector("#tickerNavigation");

    // The navigation ticker is sometimes not present on the page
    // This can happen when is_nba_sib is set to true and page.nba_ticker_menu is empty
    if (!tickerNavigation) {
        return;
    }

    if (nbaTeamsTicker && nbaTeamsDropdown) {
        setupTickerHover(nbaTeamsTicker, tickerNavigation, nbaTeamsDropdown);
    }

    if (nbaGlobalTicker && nbaGlobalDropdown) {
        setupTickerHover(nbaGlobalTicker, tickerNavigation, nbaGlobalDropdown);
    }
};

const alignTickerDropdownToLeft = () => {
    const tickerNav = document.querySelector("#tickerNavigation");

    // The navigation ticker is sometimes not present on the page
    // This can happen when is_nba_sib is set to true and page.nba_ticker_menu is empty
    if (!tickerNav) {
        return;
    }

    const tickerNavRect = tickerNav.getBoundingClientRect();
    const nbaTeamsDropdown = document.querySelector("#nbaTeamsDropdown");
    const nbaGlobalDropdown = document.querySelector("#nbaGlobalDropdown");

    if (!nbaTeamsDropdown || !nbaGlobalDropdown) {
        return;
    }

    nbaTeamsDropdown.style.left = 0;
    nbaTeamsDropdown.style.transform = `translateX(${tickerNavRect.left}px)`;
    nbaGlobalDropdown.style.transform = `translateX(${tickerNavRect.left}px)`;
};

export const updateTickerNavDropdownPosition = () => {
    handleMediaQueryChange(null, alignTickerDropdownToLeft);
    handleMediaQueryChange(
        null,
        window.addEventListener("resize", debounce(alignTickerDropdownToLeft)),
    );
};

let overflowViewportWidth = null; // Store the viewport width where overflow was detected

const desktopOverflowNavDetector = () => {
    const desktopNavigation = document.querySelector("#desktopNavigation");
    const desktopNavigationLogo = document.querySelector(
        "#desktopNavigationLogo",
    );
    const desktopLogoDivider = document.querySelector("#desktopLogoDivider");
    const burgerMenu = document.querySelector("#burgerMenu");
    const DESKTOP_NAVIGATION_PADDING = 32;

    if (!desktopNavigation || !desktopNavigationLogo) return;

    let desktopNavigationWidth =
        desktopNavigation.offsetWidth + desktopNavigationLogo.offsetWidth;
    let documentWidth =
        document.documentElement.clientWidth - DESKTOP_NAVIGATION_PADDING;

    if (desktopNavigationWidth > documentWidth) {
        if (documentWidth !== overflowViewportWidth) {
            // Only toggle if it's the first time we detect overflow
            overflowViewportWidth = documentWidth;

            desktopNavigation.classList.remove("md:flex");
            burgerMenu.classList.remove("md:hidden");
            desktopLogoDivider.classList.remove("md:block");

            // Burger Toggle
            toggleMobileSidebarMenu();

            // Edition Picker
            const editionButtonLocator = "#mobileEditionButton";
            const editionsMenuLocator = "#mobileEditionsMenu";
            const editionButtonArrowLocator = "#mobileEditionButtonArrow";

            toggleEditionMenu(
                editionButtonLocator,
                editionButtonArrowLocator,
                editionsMenuLocator,
            );
        }
    } else {
        if (documentWidth >= overflowViewportWidth) {
            overflowViewportWidth = null;

            desktopNavigation.classList.add("md:flex");
            burgerMenu.classList.add("md:hidden");
            desktopLogoDivider.classList.add("md:block");
        }
    }
};

export const overflowDetector = () => {
    handleMediaQueryChange(desktopOverflowNavDetector(), null);
    handleMediaQueryChange(
        window.addEventListener("resize", desktopOverflowNavDetector),
        null,
    );
};

// Desktop primary navigation dropdown offset position
const secondaryMenuDropdownPlacement = () => {
    const secondaryNav = document.querySelector("#desktopAdditionalMenu");
    const secondaryNavItems = document.querySelectorAll(
        "#desktopAdditionalMenu > div",
    );

    secondaryNavItems.forEach((item) => {
        const dropdown = item.querySelector("div");
        if (!dropdown) {
            return;
        }

        const dropdownRect = dropdown.getBoundingClientRect();
        const secondaryNavRect = secondaryNav.getBoundingClientRect();

        if (dropdown.classList.contains("md:-translate-x-1/2")) {
            const dropdownRectRight = dropdownRect.right;
            const secondaryNavRectRight = secondaryNavRect.right;

            if (dropdownRectRight > secondaryNavRectRight) {
                dropdown.style.left = `calc(50% - ${dropdownRectRight - secondaryNavRectRight}px)`;
            } else {
                dropdown.style.left = "50%";
            }
        }
    });
};

let expandedDropdownPositions = [];
let condensedDropdownPositions = [];

const getExpandedDropdownsPosition = () => {
    const primaryNav = document.querySelector("#headerNavigation");
    const primaryNavItems = document.querySelectorAll(
        "#desktopPrimaryMenu > [data-menu-item]",
    );

    const EXPANDED_CONDENSED_DIFFERENCE = 168;
    const NAVIGATION_PADDINGS = 23;
    const BORDER_WIDTH = 2;
    const extraLeftSpacing =
        (window.innerWidth - primaryNav.offsetWidth) / 2 - NAVIGATION_PADDINGS;

    const pushDropdownPosition = (
        dropdownPositionsArray,
        id,
        position,
        transform,
    ) => {
        dropdownPositionsArray.push({
            id,
            position,
            transform,
        });
    };

    primaryNavItems.forEach((item, id) => {
        // Skip if the item is hidden
        if (item.classList.contains("md:hidden")) return;

        const dropdown = item.querySelector("[data-has-dropdown]");
        if (!dropdown) return; // Skip if no dropdown

        const itemRect = item.getBoundingClientRect();
        const dropdownRect = dropdown.getBoundingClientRect();
        const primaryNavRect = primaryNav.getBoundingClientRect();

        const dropdownLeft = Math.round(dropdownRect.left);
        const primaryNavLeft = Math.round(primaryNavRect.left);
        const itemLeft = Math.round(itemRect.left);

        const expandedPosition = dropdownLeft - primaryNavLeft - BORDER_WIDTH;
        const condensedPosition =
            dropdownLeft -
            EXPANDED_CONDENSED_DIFFERENCE -
            primaryNavLeft -
            BORDER_WIDTH;

        let isDropdownOverlap =
            itemLeft - primaryNavLeft < itemLeft - dropdownLeft;

        // Handle expanded dropdown positions
        if (expandedPosition < primaryNavLeft - extraLeftSpacing) {
            pushDropdownPosition(
                expandedDropdownPositions,
                id,
                isDropdownOverlap ? 0 : "50%",
                isDropdownOverlap,
            );
        } else {
            pushDropdownPosition(expandedDropdownPositions, id, "50%", null);
        }

        // Handle condensed dropdown positions
        if (condensedPosition < primaryNavLeft - extraLeftSpacing) {
            pushDropdownPosition(
                condensedDropdownPositions,
                id,
                isDropdownOverlap ? "50%" : condensedPosition,
                isDropdownOverlap,
            );
        } else {
            pushDropdownPosition(condensedDropdownPositions, id, null, null);
        }
    });
};

const primaryMenuDropdownPlacement = () => {
    const primaryNavItems = document.querySelectorAll(
        "#desktopPrimaryMenu > [data-menu-item]",
    );

    primaryNavItems.forEach((item, index) => {
        if (!item.classList.contains("md:hidden")) {
            const dropdown = item.querySelector("[data-has-dropdown");
            const tsnLogo = document.querySelector("#tsnLogo");
            const condensedLogo = tsnLogo.classList.contains(
                "bg-condensed-tsn-logo",
            );
            const expandedLogo = tsnLogo.classList.contains(
                "bg-expanded-tsn-logo",
            );

            if (condensedLogo) {
                const condensedDropdownData = condensedDropdownPositions.find(
                    (obj) => obj.id === index,
                );

                if (condensedDropdownData) {
                    dropdown.style.setProperty(
                        "left",
                        condensedDropdownData.position === 0
                            ? "50%"
                            : `calc(50% - ${condensedDropdownData.position}px`,
                    );
                    if (condensedDropdownData.transform) {
                        dropdown.style.setProperty("transform", `none`);
                        item.style.setProperty("position", `static`);
                    }
                }
            }

            if (expandedLogo) {
                const expandedDropdownData = expandedDropdownPositions.find(
                    (obj) => obj.id === index,
                );
                if (expandedDropdownData) {
                    dropdown.style.setProperty(
                        "left",
                        expandedDropdownData.position,
                    );

                    if (expandedDropdownData.transform) {
                        dropdown.style.setProperty("transform", `none`);
                        item.style.setProperty("position", `static`);
                    }
                }
            }
        }
    });
};

const preSetHoverBoldText = (items, direction) => {
    if (items.length === 0) return;

    const BOLD_FONT_WIDTH_ALLOWANCE = 2;

    if (direction === "column") {
        const baseWidth = items[0].offsetWidth;
        const adjustedWidth = `${baseWidth + BOLD_FONT_WIDTH_ALLOWANCE}px`;
        items.forEach((item) => {
            item.style.width = adjustedWidth;
        });
    }

    if (direction === "row") {
        items.forEach((item) => {
            item.style.width = `${item.offsetWidth + BOLD_FONT_WIDTH_ALLOWANCE}px`;

            if (item.classList.contains("mr-0.5")) {
                item.classList.remove("mr-0.5");
            }
        });
    }
};

const checkTeamName = (teams) => {
    let isTeamNameWrapped = false;
    if (teams) {
        teams.forEach((team) => {
            team.querySelectorAll("[data-team-title]").forEach((title) => {
                const city = title.children[0];
                const name = title.children[title.children.length - 1];

                if (
                    city.getBoundingClientRect().top !==
                    name.getBoundingClientRect().top
                ) {
                    isTeamNameWrapped = true;
                }
            });
        });
    }

    if (isTeamNameWrapped) {
        teams.forEach((team) => {
            team.querySelectorAll("[data-team-title]").forEach((title) => {
                const city = title.children[0];
                city.style.display = "none";
            });
        });
    }
};

const getTeamsDivision = () => {
    const dropdownWithTeams = document.querySelectorAll(
        "[data-has-dropdown] [data-sub-menu-team]",
    );

    dropdownWithTeams.forEach((teamDropdown) => {
        const division = teamDropdown.querySelector("[data-teams-division]");
        if (!division) return;

        const teams = division.querySelectorAll("[data-team]");
        const dropdown = teamDropdown.closest("[data-has-dropdown]");
        const menu = dropdown.closest("[data-menu-item]");

        switch (teams.length) {
            case 1:
                division.classList.add("js-grid-cols-1");
                dropdown.classList.add("js-dropdown-centered");
                menu.classList.add("md:relative");
                break;
            case 2:
                division.classList.add("js-grid-cols-2");
                dropdown.classList.add("js-dropdown-centered");
                menu.classList.add("md:relative");
                break;
            case 3:
                division.classList.add("js-grid-cols-3");
                dropdown.classList.add("js-dropdown-centered");
                menu.classList.add("md:relative");
                break;
            default: {
                const threeColumnSports = dropdown.querySelectorAll(
                    "a[href*='/nba'], a[href*='/mlb'], a[href*='/soccer']",
                );

                if (threeColumnSports.length > 0) {
                    threeColumnSports.forEach((dropdown) => {
                        dropdown.classList.add("js-dropdown-centered");
                        division.classList.add("js-grid-cols-3");
                        menu.classList.add("js-relative-three-column");
                    });
                } else {
                    division.classList.add("js-grid-cols-4");
                }
                break;
            }
        }

        checkTeamName(teams);
        preSetHoverBoldText(teams, "column");
    });
};

export const updatePrimaryNavDropdownPosition = () => {
    handleMediaQueryChange(null, getTeamsDivision);
    handleMediaQueryChange(null, getExpandedDropdownsPosition);
    handleMediaQueryChange(null, primaryMenuDropdownPlacement);
    handleMediaQueryChange(null, secondaryMenuDropdownPlacement());

    handleMediaQueryChange(
        null,
        monitorForChanges("#tsnLogo", primaryMenuDropdownPlacement),
    );
};
